import lottie from 'lottie-web'

const mount = (container, data, options) =>
  options
    ? lottie.loadAnimation(options)
    : lottie.loadAnimation({
        container: container,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: data,
      })

export default { mount }
