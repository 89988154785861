const getAvailableLanguages = () => {
  const language = document.querySelector('.navigation__meta-header__language');
  if (!language) return;
  const publicLanguages = document.querySelectorAll('.availableLanguagesModal');
  const publicLanguagesIds = getPublicLanguagesIdList(publicLanguages);
  const privateLanguage = themeDisplay.getLanguageId().split("_")[0];

  let dismiss = localStorage.getItem("dismiss");

  if(dismiss == null && !compareLanguages(privateLanguage,publicLanguagesIds)){
    $('#modalIdioma').addClass('visible');
    $('#modalIdiomaLayer').addClass('visible');
    $('#modalIdioma').addClass('c-modal-idiomas');
  }
}

const compareLanguages = (privateLanguage, publicLanguages) => {
    if(publicLanguages){
        for(let language of publicLanguages){
            if(privateLanguage === language){
                return true;
            }
        }
    }
    return false;
}

function getPublicLanguagesIdList(htmlNodes) {
    const idiomas = [];
    let i = 0;

    for(; i<htmlNodes.length; i++) {
        const node = htmlNodes[i].value;
        const idiomaId = node.split("_")[0];
        idiomas.push(idiomaId);
    };
    return idiomas;
}

export default { getAvailableLanguages }