const toggleOpen = () => {
  const buttons = document.querySelectorAll('.footer__links__container__toggle')

  if (buttons.length === 0) return

  const closeToggles = () => buttons.forEach((button) => button.classList.remove('is-open'))

  const toggle = (button) => {
    if (button.classList.contains('is-open')) {
      closeToggles()
    } else {
      closeToggles()
      button.classList.add('is-open')
    }
  }

  buttons.forEach((button) => {
    button.addEventListener('click', () => toggle(button))
    return button.removeEventListener('click', () => toggle(button))
  })
}

const reorderColumns = () => {
  const columns = document.querySelectorAll('.footer__links__container')

  if (columns.length === 0) return

  const wrapper = document.querySelector('.footer__links .section-wrapper')
  const rrss = document.querySelector('.footer__rrss')
  const lastColum = columns[columns.length - 1]

  if (window.innerWidth >= 600) {
    rrss.remove()
    lastColum.appendChild(rrss)
  } else {
    const rrssInColumn = lastColum.querySelector('.footer__rrss')
    if (rrssInColumn) {
      rrssInColumn.remove()
      wrapper.prepend(rrss)
    }
  }
}


export default { toggleOpen, reorderColumns }
