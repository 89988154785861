const toggleAppear = () => {
  const navigation = document.querySelector('.navigation')

  if (!navigation) return

  let oldValue = 0
  let newValue = 0

  window.addEventListener('scroll', () => {
    newValue = window.pageYOffset

    if (oldValue < newValue && newValue > 60) {
      navigation.classList.add('is-hidden')
    } else if (oldValue > newValue) {
      navigation.classList.remove('is-hidden')
    }
    oldValue = newValue
  })
}

export default { toggleAppear }
