const appear = (gsap) => {
  const strokes = document.querySelectorAll('.stroke-overlay')
  const isAccessible =
    document.documentElement.classList.contains('is-accessible')
  if (isAccessible || strokes.length === 0) return

  return strokes.forEach((stroke) => {
    const layerTop = stroke.querySelector('.is-top')
    const layerRight = stroke.querySelector('.is-right')
    const layerBottom = stroke.querySelector('.is-bottom')
    const layerLeft = stroke.querySelector('.is-left')

    const duration = 1
    const ease = 'power4.in'

    gsap
      .timeline({
        scrollTrigger: {
          trigger: stroke,
          start: 'top center',
        },
      })
      .to(
        [layerTop, layerBottom],
        {
          scaleY: 0,
          duration: duration,
          ease: ease,
        },
        '-=0.4',
      )
      .to(
        [layerRight, layerLeft],
        {
          scaleY: 1,
          duration: duration,
          ease: ease,
        },
        `-=${duration}`,
      )
      .to(
        layerRight,
        {
          xPercent: 100,
          duration: duration,
          ease: ease,
        },
        `-=${duration / 1.5}`,
      )
      .to(
        layerLeft,
        {
          xPercent: -100,
          duration: duration,
          ease: ease,
        },
        `-=${duration}`,
      )
      .to(
        stroke,
        {
          opacity: 0,
          duration: duration,
          ease: ease,
        },
        `-=${duration}`,
      )
  })
}

export default { appear }
