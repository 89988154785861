import lottie from '../components/lottie'

const appear = (gsap) => {
  const agents = document.querySelector('.agents:not(.is-detail)')
  const isAccessible =
    document.documentElement.classList.contains('is-accessible')
  if (isAccessible || !agents) return

  const dataNumber = agents.getAttribute('data-number')
  const number = agents.querySelector('.agents__number')
  const title = agents.querySelector('.agents__title')
  const subtitle = agents.querySelector('.agents__subtitle')
  const button = agents.querySelector('.button')
  const duration = 1
  let num = {}
  if (!isNaN(dataNumber)) {
      num = { var: (dataNumber / 2).toFixed(0) }
      number.innerHTML = (dataNumber / 2).toFixed(0)
  }
  gsap
    .timeline({
      scrollTrigger: {
        trigger: agents,
        start: 'top bottom',
      },
    })
    .to(num, {
      var: dataNumber,
      duration: duration,
      onUpdate: () => (number.innerHTML = num.var.toFixed(0)),
    })
    .to(
      title,
      {
        opacity: 1,
        duration: duration,
      },
      `-=${duration}`,
    )
    .to(
      subtitle,
      {
        opacity: 1,
        duration: duration,
      },
      `-=${duration}`,
    )
    .to(
      button,
      {
        opacity: 1,
        duration: duration,
      },
      `-=${duration / 2}`,
    )
}

const lottieProgress = (ScrollTrigger) => {
  const agents = document.querySelector('.agents')
  if (!agents) return

  const container = agents.querySelector('.agents__lottie')
  if (!container) return

  const data = container.getAttribute('data-lottie')
  const animation = lottie.mount(container, data)

  const isAccessible =
    document.documentElement.classList.contains('is-accessible')
  if (!isAccessible) {
    ScrollTrigger.create({
      trigger: container,
      start: 'top bottom',
      end: 'bottom top',
      onUpdate: (self) => {
        const totalFrames = animation.totalFrames - 1
        const currentProgress = (self.progress * 100).toFixed(0)
        const currentFrame = parseInt((currentProgress * totalFrames) / 100)
        animation.goToAndStop(currentFrame, true)
      },
    })
  }
}

export default { appear, lottieProgress }
