import lottie from '../components/lottie'

const appear = (gsap) => {
  const slides = document.querySelectorAll('.services-slide')
  const isAccessible =
    document.documentElement.classList.contains('is-accessible')
  if (isAccessible || slides.length === 0) return

  return slides.forEach((slide) => {
    const service = slide.querySelector('.services-slide__service')
    const title = slide.querySelector('.services-slide__title')
    const subtitle = slide.querySelector('.services-slide__subtitle')
    const button = slide.querySelector('.button')

    const duration = 1

    gsap
      .timeline({
        scrollTrigger: {
          trigger: slide,
          start: 'top center',
        },
      })
      .to(service, {
        opacity: 1,
        duration: duration,
      })
      .to(
        title,
        {
          opacity: 1,
          duration: duration,
        },
        `-=${duration}`,
      )
      .to(
        subtitle,
        {
          opacity: 1,
          duration: duration,
        },
        `-=${duration}`,
      )
      .to(
        button,
        {
          opacity: 1,
          duration: duration,
        },
        `-=${duration / 2}`,
      )
  })
}

const mountLotties = () => {
  const slides = document.querySelectorAll('.services-slide')
  if (slides.length === 0) return

  const services = document.querySelector('.services__container')
  if (!services)
    slides.forEach((slide) => {
      const container = slide.querySelector('.services-slide__lottie')
      const data = container.getAttribute('data-lottie')
      lottie.mount(container, data)
    })
}

export default { appear, mountLotties }
