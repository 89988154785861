const appear = (gsap) => {
  const slides = document.querySelectorAll('.media-slide')
  const isAccessible =
    document.documentElement.classList.contains('is-accessible')
  if (isAccessible || slides.length === 0) return

  return slides.forEach((slide) => {
    const title = slide.querySelector('.media-slide__title')
    const subtitle = slide.querySelector('.media-slide__subtitle')
    const button = slide.querySelector('.button')

    const duration = 1

    return gsap
      .timeline({
        scrollTrigger: {
          trigger: slide,
          start: 'top center',
        },
      })
      .to(
        title,
        {
          opacity: 1,
          duration: duration,
        },
        `-=${duration / 2}`,
      )
      .to(
        subtitle,
        {
          opacity: 1,
          y: 0,
          duration: duration,
        },
        `-=${duration}`,
      )
      .to(
        button,
        {
          opacity: 1,
          duration: duration,
        },
        `-=${duration / 2}`,
      )
  })
}

const videoPlayer = () => {
  const slides = document.querySelectorAll('.media-slide')
  if (slides.length === 0) return

  return slides.forEach((slide) => {
    const cta = slide.querySelector('.button')
    const video = slide.querySelector('.media-slide__video:not(.is-mobile)')

    const playVideo = () => {
      if (video.requestFullscreen) {
        video.requestFullscreen()
      } else if (video.webkitRequestFullscreen) {
        /* Safari */
        video.webkitRequestFullscreen()
      } else if (video.msRequestFullscreen) {
        /* IE11 */
        video.msRequestFullscreen()
      }
      video.currentTime = 0
      video.play()
      video.muted = false
      if (document.documentElement.classList.contains('is-accessible'))
        video.style.display = 'block'

      document.addEventListener('fullscreenchange', () => {
        if (!document.fullscreenElement) {
          video.currentTime = 0
          video.play()
          video.muted = true
          if (document.documentElement.classList.contains('is-accessible')) {
            video.pause()
            video.style.display = 'none'
          }
        }
      })
      document.addEventListener('webkitfullscreenchange', () => {
        if (!document.webkitFullscreenElement) {
          video.currentTime = 0
          video.play()
          video.muted = true
          if (document.documentElement.classList.contains('is-accessible')) {
            video.pause()
            video.style.display = 'none'
          }
        }
      })
    }

    if (cta && video) {
      return cta.addEventListener('click', playVideo)
    } else return
  })
}

export default { appear, videoPlayer }
