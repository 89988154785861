const appear = (gsap) => {
  const insuranceList = document.querySelector('.insurance-list')
  const isAccessible =
    document.documentElement.classList.contains('is-accessible')
  if (isAccessible || !insuranceList) return

  const imagesWrapper = insuranceList.querySelector('.insurance-list__illustrations')
  const images = imagesWrapper.querySelectorAll('img')
  const title = insuranceList.querySelector('.insurance-list__title')
  const titleTexts = title.querySelectorAll('.insurance-list__title__text')
  const list = insuranceList.querySelector('.insurance-list__list')

  const subtitle = insuranceList.querySelector('.insurance-list__subtitle')
  const paragraph = insuranceList.querySelector('.insurance-list__paragraph')
  const button = insuranceList.querySelector('.button')

  const duration = 1
  const alturaDelViewport = window.innerHeight


  window.addEventListener("scroll", function () {
    const pixel = window.scrollY
    const alturaAnimation = window.innerHeight / 2
    if (pixel >= alturaAnimation) {
      imagesWrapper.style.cssText = 'transform: translate(0%, -50%);'
      images[0].style.cssText = 'opacity: 1;'
      title.style.cssText = 'transform: translateY(0%);'
      titleTexts[0].style.cssText = 'opacity: 0;'
      titleTexts[1].style.cssText = 'opacity: 1;'
      list.style.cssText = 'transform: translate(0, 0);'
      subtitle.style.cssText = 'opacity: 1;'
      paragraph.style.cssText = 'opacity: 1;'
      button.style.cssText = 'opacity: 1;'
    }

    //   

  })

  // gsap.to(imagesWrapper, {
  //   x: 0,
  //   scrollTrigger: {
  //     trigger: imagesWrapper,
  //     start: 'top bottom',
  //     end: 'center center',
  //     scrub: true,
  //     once: true,
  //   }
  // })
  // gsap.to(images[0], {
  //   opacity: 1,
  //   scrollTrigger: {
  //     trigger: imagesWrapper,
  //     start: 'top 55%',
  //     end: 'center center',
  //     scrub: true,
  //     once: true,
  //   }
  // })
  // gsap.to(title, {
  //   y: 0,
  //   scrollTrigger: {
  //     trigger: insuranceList,
  //     start: '80px bottom',
  //     end: 'center center',
  //     scrub: true,
  //     once: true,
  //     onLeave: () => gsap.timeline()
  //       .to(titleTexts[0], {
  //         opacity: 0,
  //         duration: duration
  //       })
  //       .to(titleTexts[1], {
  //         opacity: 1,
  //         duration: duration
  //       })
  //   }
  // })
  // gsap.to(list, {
  //   y: 0,
  //   scrollTrigger: {
  //     trigger: insuranceList,
  //     start: '80px bottom',
  //     end: 'center center',
  //     scrub: true,
  //     once: true,
  //     onLeave: () => gsap.timeline()
  //       .to([subtitle, button], {
  //         opacity: 1,
  //         duration: duration,
  //         stagger: duration / 3
  //       })
  //   }
  // })
}

export default { appear }
