import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Splide from '@splidejs/splide'

import agents from './components/agents'
import card from './components/card'
import carousel from './components/carousel'
import footer from './components/footer'
import header from './components/header'
import help from './components/help'
import hero from './components/hero'
import insuranceList from './components/insurance-list'
import mediaSlide from './components/media-slide'
import metaHeader from './components/meta-header'
import mobileHeader from './components/mobile-header'
import navigation from './components/navigation'
import phones from './components/phones'
import services from './components/services'
import servicesSlide from './components/services-slide'
import strokeOverlay from './components/stroke-overlay'
import suggestions from './components/suggestions'
import languageModal from './components/language-modal'

import analyticFunctionsActivate from './components/analytics-functions';

const handleResize = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

// --- On load ---
document.addEventListener('DOMContentLoaded', () => {
  handleResize()
  languageModal.getAvailableLanguages()
  // Libs callbacks
  gsap.registerPlugin(ScrollTrigger)

  // Components callbacks
  agents.appear(gsap)
  services.pin(gsap, ScrollTrigger)
  // card.lazyVideos()
  card.lottieProgress(ScrollTrigger)
  card.pin(gsap, ScrollTrigger)
  card.videoToggle()
  carousel.mount(Splide)
  footer.toggleOpen()
  footer.reorderColumns()
  help.appear(gsap)
  insuranceList.appear(gsap)
  agents.lottieProgress(ScrollTrigger)
  header.toggleFinder()
  header.toggleHover()
  header.toggleOpen()
  help.lottieProgress(ScrollTrigger)
  hero.appear(gsap)
  // hero.lazyVideos()
  hero.videoPlayer()
  mediaSlide.appear(gsap)
  mediaSlide.videoPlayer()
  metaHeader.toggleLanguage()
  metaHeader.toggleLogged()
  mobileHeader.toggleFinder()
  mobileHeader.toggleOpen()
  navigation.toggleAppear()
  phones.toggleCategories()
  phones.toggleOpen()
  phones.toggleSize(ScrollTrigger)
  strokeOverlay.appear(gsap)
  servicesSlide.appear(gsap)
  servicesSlide.mountLotties()
  suggestions.appear(ScrollTrigger)
  suggestions.close()

  // Función encargada de activar la analítica
  analyticFunctionsActivate();
})

// --- On resize ---
window.addEventListener('resize', () => {
  ScrollTrigger.refresh()
  handleResize()

  // Components callbacks
  footer.reorderColumns()
})

// --- On scroll ---
// window.addEventListener('scroll', () => {
//   const callback = () => {
//     // Your callbacks here 👇
//   }
//   requestAnimationFrame(callback)
// })
