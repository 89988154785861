const appear = (ScrollTrigger) => {
  const suggestions = document.querySelector('.suggestions')
  const insuranceList = document.querySelector('.insurance-list')
  if (!suggestions || !insuranceList) return
  const isAccessible =
    document.documentElement.classList.contains('is-accessible')
  if (isAccessible) {
    suggestions.classList.remove('is-hidden')
    return
  }

  ScrollTrigger.create({
    trigger: insuranceList,
    start: 'bottom bottom',
    once: true,
    onEnter: () => suggestions.classList.remove('is-hidden'),
  })
}

const close = () => {
  const suggestions = document.querySelector('.suggestions')
  if (!suggestions) return

  const button = document.querySelector('.suggestions__button')
  return button.addEventListener('click', () =>
    suggestions.classList.add('is-hidden'),
  )
}

export default { appear, close }
