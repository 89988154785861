import lottie from '../components/lottie'

const pin = (gsap, ScrollTrigger) => {
  const services = document.querySelector('.services__container')
  if (!services) return

  const slides = services.querySelectorAll('.services-slide')
  // const end = services.clientWidth * slides.length
  // services.style.height = `${end}px`

  const updateAnimation = (animation, self) => {
    const totalFrames = animation.totalFrames - 1
    const currentProgress = (self.progress * 100).toFixed(0)
    const currentFrame = parseInt((currentProgress * totalFrames) / 100)
    animation.goToAndStop(currentFrame, true)
  }

  slides.forEach((slide) => {
    const container = slide.querySelector('.services-slide__lottie')
    const data = container.getAttribute('data-lottie')
    const animation = lottie.mount(container, data)

    const isAccessible =
      document.documentElement.classList.contains('is-accessible')
    if (!isAccessible)
      ScrollTrigger.matchMedia({
        '(min-width: 1025px)': () => {
          gsap.to(slide, {
            xPercent: -100 * (slides.length - 1),
            ease: 'none',
            scrollTrigger: {
              trigger: services,
              pin: true,
              pinSpacing: true,
              scrub: true,
              snap: 1 / (slides.length - 1),
              // end: end,
              onUpdate: (self) => updateAnimation(animation, self),
            },
          })
        },
        '(max-width: 1024px)': () => {
          gsap.to(container, {
            scrollTrigger: {
              trigger: container,
              onUpdate: (self) => updateAnimation(animation, self),
            },
          })
        },
      })
  })
}

export default { pin }
