import lottie from '../components/lottie'

const lazyVideos = () => {
  const cards = document.querySelectorAll('.card')
  if (cards.length === 0) return

  const videos = [].slice.call(document.querySelectorAll('.card video'))

  if ('IntersectionObserver' in window) {
    const lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (let source in video.target.children) {
            let videoSource = video.target.children[source]
            if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
              videoSource.src = videoSource.dataset.src
            }
          }

          video.target.load()
          video.target.classList.remove('lazy')
          lazyVideoObserver.unobserve(video.target)
        }
      })
    })

    videos.forEach((lazyVideo) => lazyVideoObserver.observe(lazyVideo))
  }
}

const lottieProgress = (ScrollTrigger) => {
  const cards = document.querySelectorAll('.card')
  if (cards.length === 0) return

  return cards.forEach((card) => {
    const container = card.querySelector('.card__media-block__lottie')

    if (container) {
      const isAccessible =
        document.documentElement.classList.contains('is-accessible')
      const data = container.getAttribute('data-lottie')
      const animation = lottie.mount(container, data)

      ScrollTrigger.create({
        trigger: container,
        start: 'top 80%',
        end: 'center center',
        onUpdate: (self) => {
          const totalFrames = animation.totalFrames - 1
          if (isAccessible) {
            animation.goToAndStop(totalFrames, true)
          } else {
            const currentProgress = (self.progress * 100).toFixed(0)
            const currentFrame = parseInt((currentProgress * totalFrames) / 100)
            animation.goToAndStop(currentFrame, true)
          }
        },
      })
    }
  })
}

const pin = (gsap, ScrollTrigger, SplitText) => {
  const cards = document.querySelectorAll('.card')
  const isAccessible =
    document.documentElement.classList.contains('is-accessible')
  if (isAccessible || cards.length === 0) return

  return cards.forEach((card, index) => {
    const title = card.querySelector('.card__info-block__title')
    const subtitle = card.querySelector('.card__info-block__subtitle')
    const text = card.querySelector('.card__info-block__text')
    const link = card.querySelector('.card__info-block__link')

    const duration = 0.5

    const appear = () =>
      gsap
        .timeline()
        .to(title, {
          opacity: 1,
          duration: duration,
        })
        .to(
          [subtitle, link],
          {
            opacity: 1,
            duration: duration,
          },
          `-=${duration / 2}`,
        )
        .to(
          text,
          {
            opacity: 1,
            duration: duration,
          },
          `-=${duration / 2}`,
        )

    // const hidde = () =>
    //   gsap
    //     .timeline()
    //     .to(title, {
    //       opacity: 0,
    //       duration: duration,
    //     })
    //     .to(
    //       subtitle,
    //       {
    //         opacity: 0,
    //         duration: duration,
    //       },
    //       `-=${duration / 2}`,
    //     )
    //     .to(
    //       split.words,
    //       {
    //         opacity: 0,
    //         duration: duration,
    //       },
    //       `-=${duration / 2}`,
    //     )

    ScrollTrigger.create({
      trigger: card,
      start: 'bottom bottom',
      pin: index + 1 < cards.length ? true : false,
      pinSpacing: false,
      snap: window.innerWidth < 768 ? false : true,
    })

    ScrollTrigger.create({
      trigger: card,
      start: 'top 25%',
      end: 'bottom 75%',
      onEnter: () => appear(),
      // onLeave: () => hidde(),
      // onLeaveBack: () => hidde(),
      // onEnterBack: () => appear(),
    })
  })
}

const videoToggle = () => {
  const cards = document.querySelectorAll('.card')
  if (cards.length === 0) return
  const isAccessible =
    document.documentElement.classList.contains('is-accessible')

  if (isAccessible)
    return cards.forEach((card) => {
      const video = card.querySelector('video')
      if (video) video.remove()
    })
}

export default { lazyVideos, lottieProgress, pin, videoToggle }
