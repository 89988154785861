const appear = (gsap) => {
  const heros = document.querySelectorAll('.hero')
  const isAccessible =
    document.documentElement.classList.contains('is-accessible')
  if (isAccessible || heros.length === 0) return

  heros.forEach((hero) => {
    const title = hero.querySelector('.hero__title')
    const subtitle = hero.querySelector('.hero__subtitle')
    const button = hero.querySelector('.button')

    const duration = 1

    return gsap
      .timeline({
        scrollTrigger: {
          trigger: hero,
          start: 'top center'
        }
      })
      .to(title, {
        opacity: 1,
        duration: duration,
        delay: duration * 1.25,
      })
      .to(
        subtitle,
        {
          opacity: 1,
          y: 0,
          duration: duration,
        },
        `-=${duration}`,
      )
      .to(
        button,
        {
          opacity: 1,
          duration: duration,
        },
        `-=${duration / 2}`,
      )
  })
}

const lazyVideos = () => {
  const hero = document.querySelector('.hero')
  if (!hero) return

  const videos = [].slice.call(hero.querySelectorAll('video'))

  if ('IntersectionObserver' in window) {
    const lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (let source in video.target.children) {
            let videoSource = video.target.children[source]
            if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
              videoSource.src = videoSource.dataset.src
            }
          }

          video.target.load()
          video.target.classList.remove('lazy')
          lazyVideoObserver.unobserve(video.target)
        }
      })
    })

    videos.forEach((lazyVideo) => lazyVideoObserver.observe(lazyVideo))
  }
}

const videoPlayer = () => {
  const hero = document.querySelector('.hero')
  if (!hero) return

  const cta = hero.querySelector('.button')
  const video = hero.querySelector('.hero__video:not(.is-mobile)')
  const playVideo = () => {
    video.style.display = 'block'

    if (video.requestFullscreen) {
      video.requestFullscreen()
    } else if (video.webkitEnterFullScreen) {
      /* Safari */
      video.webkitEnterFullScreen()
    } else if (video.msRequestFullscreen) {
      /* IE11 */
      video.msRequestFullscreen()
    }
    video.currentTime = 0
    video.play()
    video.muted = false
    if (document.documentElement.classList.contains('is-accessible'))
      video.style.display = 'block'

    document.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        video.currentTime = 0
        video.play()
        video.muted = true
        if (document.documentElement.classList.contains('is-accessible')) {
          video.pause()
          video.style.display = 'none'
        }
      }
    })
    document.addEventListener('webkitfullscreenchange', () => {
      if (!document.webkitFullscreenElement) {
        video.currentTime = 0
        video.play()
        video.muted = true
        if (document.documentElement.classList.contains('is-accessible')) {
          video.pause()
          video.style.display = 'none'
        }
      }
    })
  }

  if (cta && video) {
    return cta.addEventListener('click', playVideo)
  } else return
}

export default { appear, lazyVideos, videoPlayer }
