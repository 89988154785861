import lottie from '../components/lottie'

const appear = (gsap) => {
  const help = document.querySelector('.help')
  const isAccessible =
    document.documentElement.classList.contains('is-accessible')
  if (isAccessible || !help) return

  const title = help.querySelector('.help__title')
  const subtitle = help.querySelector('.help__subtitle')
  const buttons = help.querySelectorAll('.button')

  const duration = 1

  return gsap
    .timeline({
      scrollTrigger: {
        trigger: help,
        start: 'top center',
      },
    })
    .to(
      title,
      {
        opacity: 1,
        duration: duration,
      },
      `-=${duration / 2}`,
    )
    .to(
      subtitle,
      {
        opacity: 1,
        duration: duration,
      },
      `-=${duration}`,
    )
    .to(
      buttons,
      {
        opacity: 1,
        duration: duration,
      },
      `-=${duration / 2}`,
    )
}

const lottieProgress = (ScrollTrigger) => {
  const help = document.querySelector('.help')
  if (!help) return

  const container = help.querySelector('.help__lottie')
  const data = container.getAttribute('data-lottie')
  const animation = lottie.mount(container, data)

  const isAccessible =
    document.documentElement.classList.contains('is-accessible')
  if (!isAccessible) {
    ScrollTrigger.create({
      trigger: container,
      start: 'top bottom',
      end: 'bottom top',
      onUpdate: (self) => {
        const totalFrames = animation.totalFrames - 1
        const currentProgress = (self.progress * 100).toFixed(0)
        const currentFrame = parseInt((currentProgress * totalFrames) / 100)
        animation.goToAndStop(currentFrame, true)
      },
    })
  }
}

export default { appear, lottieProgress }
