const toggleFinder = () => {
  const launcher = document.querySelector(
    '.navigation__header__controls__finder-launcher',
  )
  const finder = document.querySelector('.navigation__header__finder')

  if (!launcher || !finder) return

  const input = finder.querySelector('.navigation__header__finder__input')

  const clickOut = (event) => {
    if (
      launcher !== event.target &&
      !launcher.contains(event.target) &&
      finder !== event.target &&
      !finder.contains(event.target)
    ) {
      launcher.classList.remove('is-open')
      finder.classList.remove('is-open')
    }
  }

  const toggle = () => {
    if (launcher.classList.contains('is-open')) {
      launcher.classList.remove('is-open')
      finder.classList.remove('is-open')
    } else {
      launcher.classList.add('is-open')
      finder.classList.add('is-open')
      setTimeout(() => {
        input.focus()
      }, 500)
    }
  }

  document.addEventListener('click', (event) => clickOut(event))

  launcher.addEventListener('click', () => toggle())
  return launcher.removeEventListener('click', () => toggle())
}

const toggleHover = () => {
  const list = document.querySelector('.navigation__header__links')
  const links = document.querySelectorAll('.navigation__header__links li')
  const indicator = document.querySelector('.navigation__header__indicator')

  if (links.length === 0 || !indicator) return

  const backToActive = () => links.forEach((link) => {
    link.classList.contains('is-open') ? moveIndicatorToLink(link) : null
  })

  links.forEach((link) => {
    link.addEventListener('mouseenter', () => {         
        if($(link).hasClass("nav-item")){
          moveIndicatorToLink(link)
        }else{
          backToActive();
        }        
      }
    )
    return link.removeEventListener('mouseenter', () => moveIndicatorToLink(link))
  })

  list.addEventListener('mouseleave', () => backToActive())
  return list.removeEventListener('mouseleave', () => backToActive())
}

const toggleOpen = () => {
  const buttons = document.querySelectorAll(
    '.navigation__header__links__link.is-button',
  )
  const menus = document.querySelectorAll('.navigation__menu')
  const links = document.querySelectorAll('.navigation__header__links li')

  if (buttons.length === 0 || menus.length === 0) return

  const navigation = document.querySelector('.navigation')
  const indicator = document.querySelector('.navigation__header__indicator')

  const closeMenus = () => {
    menus.forEach((menu) => menu.classList.remove('is-open'))
    links.forEach((link) => link.classList.remove('is-open'))
    indicator.classList.remove('is-open')
    navigation.classList.remove('is-menu-open')
  }
  const clickOut = (event) => {
    return navigation !== event.target && !navigation.contains(event.target)
      ? closeMenus()
      : null
  }

  const toggle = (index, button, event) => {
    if (menus[index].classList.contains('is-open')) {
      closeMenus()
    } else {
      closeMenus()

      // Get index of the main menu item
      const clickedButton = button.parentElement
      const buttonParent = clickedButton.parentElement
      const navigationIndex = Array.prototype.indexOf.call(
        buttonParent.children,
        clickedButton,
      )

      const links = document.querySelectorAll('.navigation__header__links li')

      menus[index].classList.add('is-open')
      links[index].classList.add('is-open')
      indicator.classList.add('is-open')
      navigation.classList.add('is-menu-open')

      moveIndicatorToLink(links[navigationIndex])

      // HACK for scrolling navigation
      // Set focus on the submenu first link only when
      // navigating with tab
      if (event.detail === 0) {
        menus[index].querySelector('li:first-child a').focus()
      }

      menus[index].addEventListener('keydown', function (event) {
        // If it's tab and it's the last link in the submenu
        if (
          event.key === 'Tab' &&
          event.target.closest('li') ===
          event.target.closest('ul').querySelector('li:last-child')
        ) {
          event.preventDefault()
          closeMenus()

          // If there are more menu items to continue
          if (links[navigationIndex + 1]) {
            links[navigationIndex + 1].querySelector('button, a').focus()
          } else {
            // If it is the last item in the menu
            // Set the focus on the control first button
            document
              .querySelector(
                '.navigation__header__controls a, .navigation__header__controls button',
              )
              .focus()
          }
        }

        if (event.key === 'Escape') {
          closeMenus()
          links[navigationIndex].querySelector('button, a').focus()
        }
      })
    }
  }

  document.addEventListener('click', (event) => clickOut(event))

  buttons.forEach((button, index) => {
    button.addEventListener('click', (event) => toggle(index, button, event))
    return button.removeEventListener('click', (event) =>
      toggle(index, button, event),
    )
  })

  const backButtons = document.querySelectorAll(
    '.navigation__menu__back-button',
  )
  backButtons.forEach((button) => {
    button.addEventListener('click', () => closeMenus())
    return button.removeEventListener('click', () => closeMenus())
  })
}

const moveIndicatorToLink = (link) => {
  const wrapper = document.querySelector('.navigation__header .section-wrapper')
  const indicator = document.querySelector('.navigation__header__indicator')

  const width = link.getBoundingClientRect().width
  const left = link.getBoundingClientRect().left
  const wrapperLeft = wrapper.getBoundingClientRect().left

  indicator.style.width = `${width.toFixed(0)}px`
  indicator.style.transform = `translateX(${(left - wrapperLeft).toFixed(0)}px)`
}

export default {
  toggleFinder,
  toggleHover,
  toggleOpen
}
