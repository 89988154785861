const toggleLanguage = () => {
  const language = document.querySelector('.navigation__meta-header__language')
  if (!language) return

  const launcher = language.querySelector('.navigation__meta-header__language__launcher')
  const items = language.querySelectorAll('.navigation__meta-header__language__item')

  const clickOut = (event) => {
    if (
      launcher !== event.target &&
      !launcher.contains(event.target) &&
      language !== event.target &&
      !language.contains(event.target)
    ) {
      language.classList.remove('is-open')
    }
  }

  const toggle = () => {
    if (language.classList.contains('is-open')) {
      language.classList.remove('is-open')
    } else {
      language.classList.add('is-open')
    }
  }

  document.addEventListener('click', (event) => clickOut(event))
  launcher.addEventListener('click', () => toggle())
  return launcher.removeEventListener('click', () => toggle())
}

const toggleLogged = () => {
  const navigation = document.querySelector('.navigation')
  if (!navigation) return

  const launcher = navigation.querySelector('.button.variant-account')
  const mobileLauncher = navigation.querySelector('.navigation__mobile-header__controls__logged-launcher')
  const modal = navigation.querySelector('.logged-modal')
  if (!launcher || !mobileLauncher || !modal) return

  const closer = navigation.querySelector('.logged-modal__closer')

  const clickOut = (event) => {
    if (
      launcher !== event.target &&
      !launcher.contains(event.target) &&
      mobileLauncher !== event.target &&
      !mobileLauncher.contains(event.target) &&
      modal !== event.target &&
      !modal.contains(event.target)
    ) {
      navigation.classList.remove('is-modal-open')
      launcher.classList.remove('is-open')
    }
  }

  const toggle = () => {
    if (navigation.classList.contains('is-modal-open')) {
      navigation.classList.remove('is-modal-open')
      launcher.classList.remove('is-open')
    } else {
      navigation.classList.add('is-modal-open')
      launcher.classList.add('is-open')
    }
  }

  document.addEventListener('click', (event) => clickOut(event))
  launcher.addEventListener('click', () => toggle())
  mobileLauncher.addEventListener('click', () => toggle())
  closer.addEventListener('click', () => toggle())

  return () => {
    launcher.removeEventListener('click', () => toggle())
    mobileLauncher.removeEventListener('click', () => toggle())
    closer.removeEventListener('click', () => toggle())
  }
}


export default {
  toggleLanguage,
  toggleLogged
}
