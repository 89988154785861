const toggleCategories = () => {
  const buttons = document.querySelectorAll('button.phones__menu__content__category__button')
  const menus = document.querySelectorAll('.phones__menu__content__category ul')

  if (buttons.length === 0 || menus.length === 0) return

  const closeMenus = () => {
    menus.forEach((menu) => menu.classList.remove('is-open'))
  }

  const toggle = (index) => {
    if (menus[index].classList.contains('is-open')) {
      closeMenus()
    } else {
      closeMenus()
      menus[index].classList.add('is-open')
    }
  }

  buttons.forEach((button, index) => {
    button.addEventListener('click', () => toggle(index))
    return button.removeEventListener('click', () => toggle(index))
  })
}

const toggleOpen = () => {
  const launcher = document.querySelector('.phones__button')
  const menu = document.querySelector('.phones__menu')
  const closer = document.querySelector('.phones__menu__header__closer')

  if (!launcher || !menu || !closer) return

  const clickOut = (event) => launcher !== event.target && !launcher.contains(event.target) && menu !== event.target && !menu.contains(event.target)
    ? menu.classList.remove('is-open')
    : null

  const toggle = () => menu.classList.contains('is-open')
    ? menu.classList.remove('is-open')
    : menu.classList.add('is-open')

  document.addEventListener('click', (event) => clickOut(event))
  closer.addEventListener('click', () => menu.classList.remove('is-open'))

  launcher.addEventListener('click', () => toggle())
  return launcher.removeEventListener('click', () => toggle())
}

const toggleSize = (ScrollTrigger) => {
  const launcher = document.querySelector('.phones__button')

  if (!launcher) return

  ScrollTrigger.create({
    onUpdate: self => self.direction === 1 ? launcher.classList.add('is-collapsed') : launcher.classList.remove('is-collapsed')
  })
}

export default { toggleCategories, toggleOpen, toggleSize }
